<template>
    <div class="introduce">
        <!--page01 시작-->
        <div class="page01">
            <img src="@/assets/images/common/logo_01.svg"/>
            <div class="text_wrap">
                <div class="title">
                    <h1>Our Mission</h1>
                </div>
                <p>
                    우리의 미션은 온라인 상에서의 상거래 비즈니스를 통해 신뢰로운 관계망을 구축하고 
                    상호 간의 긍정적 시너지를 유발하게 하는 것을 가장 큰 목표로 합니다.
                </p>
                <div class="title">
                    <p>신뢰로운 비대면</p>
                    <h2><span>"R</span>eliable non-face-to-face<span>."</span></h2>
                </div>
                <p>
                    비대면 시대는 우리의 삶의 많은 변화를 가져왔습니다. 이제 B2B, B2R 유통 시장에도 신뢰로운 비대면 거래 문화와
                    시장에 적합한 시스템이 필요합니다.
                </p>
            </div>
        </div>
        <!--page01 끝-->

        <!--page02 시작-->
        <div class="page02">
            <img src="@/assets/images/introduce/page_02.jpg"/>
            <div class="text">
                <h1>META BUYSELL<br/> <span>모바일 버전 BETA 1.0</span></h1>
                <p>
                    이제 곧 당신의 무한 거래를 위한 모바일 버전이 출시됩니다.<br/> 
                    모바일에서 더 빠르게 무한 거래를 실행하세요.
                </p>
            </div>
        </div>
        <!--page02 끝-->

        <!--page03 시작-->
        <div class="page03">
            <p>
                당시 제품은 전 세계 국가들로 공급하고 있으며 <br/>
                장인의 정신과 건전한 마인드로 <br/>
                세상의 아름다운 변화에 동참하고 있습니다.
            </p>
        </div>
        <!--page03 끝-->

        <!--page04 시작-->
        <div class="page04">
            <div class="text">
                <h1>우리의 문화</h1>
                <p>
                    우리는 끊임없이 스마트한 거래 방법을 연구하고 시장문제를 
                    해결점에 접근하고 있습니다. 우리는 또한 전 세계의 모든 
                    판매자 또는 생산자들을 연결하려고 노력하고 있습니다. 
                    거래되는 상품의 제조 과정, 실제 사용 또는 폐기 과정에서, 
                    자연환경을 고려하는 회사의 제품이 더 많이 판매되고 시장에서 
                    소비되기를 바랍니다. 우리는 제품의 다양성과 거래자들의 
                    창의성을 존중하지만 환경에 대한 우리 제품의 유해한 영향을 
                    무시할 수 없습니다. 친환경 시대를 넘어 녹색생존 시대를 
                    살아가면서 친환경 소재의 혁신을 통해 제품을 시장에 
                    공급하여 소비자가 더 건강한 삶을 영위할 수 있도록 하는 
                    것입니다. 우리는 더 나은 세상과 만족스러운 거래의 경험을 
                    만들기 위해 최선을 다할 것입니다.
                </p>
            </div>

            <div class="text eng">
                <h1>Our Culture</h1>
                <p>
                    We are constantly researching smart trading methods, 
                    and approaching solutions to market problems. We are 
                    also trying to connect all sellers or producers around 
                    the world. In the manufacturing process, actual use or 
                    disposal of traded goods, we hope that more products 
                    of companies that consider the natural environment will 
                    be sold and consumed in the market. While we respect 
                    the diversity of our products and the creativity of our 
                    traders, we cannot ignore the detrimental impact of 
                    products on the environment. Going beyond the eco-
                    friendly era and living in the green survival era, we aim to 
                    supply products to the market through the innovation of 
                    eco-friendly materials so that consumers can lead a 
                    healthier life. We will do our best to create a better world 
                    and a satisfactory business experience.
                </p>
            </div>
        </div>
        <!--page04 끝-->

        <!--page05 시작-->
        <div class="page05">
            <h1><span>지능형 아바타(Intelligent avartar)</span>를 통해 새로운 거래환경을 제시합니다</h1>
            <ul>
                <li>Visual analysis</li>
                <li>AI</li>
                <li>Decision-making support</li>
                <li>Autonomous execution</li>
            </ul>
            <img src="@/assets/images/introduce/page_05.jpg"/>
        </div>
        <!--page05 끝-->

        <!--page06 시작--> 
        <div class="page06">
            <img src="@/assets/images/common/logo_01_w.svg"/>
            <p>
                미래 세대의 건강하고 아름다운 삶을 위해 <br/>
                가장 스마트한 방식으로 합리적인 공급과 <br/>
                아름다운 소바문화정착에 노력하겠습니다.
            </p>
        </div>
        <!--page06 끝--> 
    </div>
    <Footer />
</template>

<script>
import Footer from "@/components/Footer";

export default {
components: {
    Footer,
  },
}
</script>

<style scoped src="@/assets/css/layout_introduce.css">
</style>